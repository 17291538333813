import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO/>
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div class="col-lg-10">
                <div class="privacy-policy-content">
                  <h2 style={{ fontSize: "var(--h3)" }}>
                  Privacy Policy updated on May 15, 2024
                  </h2>
                  <h3 style={{ fontSize: "var(--h4)" }}>Introduction</h3>
                  <p>
                    This Privacy Policy describes the policies and procedures of
                    Zuse Technologies on the collection, use, and disclosure of
                    your information when you use the Service, and informs you
                    about your privacy rights and how the law protects you.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Definitions</h3>
                  <p>
                    The terms used in this Privacy Policy have specific meanings
                    which are defined in the policy document. These include
                    terms like Personal Data, Service, Personal Data, Usage
                    Data, and others.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Types of Data Collected</h3>
                  <p>
                    Various types of data are collected including Phone Number, Email Address,
                    Name, Usage Data, and Device identifiers
                    among others.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Tracking Technologies and Cookies</h3>
                  <p>
                    We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Information on the specific types of cookies used and their
                    management is provided in our detailed cookies section.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Use of Your Personal Data</h3>
                  <p>
                    The Company may use Personal Data for various purposes
                    including to provide and maintain our Service, manage your
                    Account, contact you, manage your requests, and for business
                    transfers, among other uses.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Retention of Your Personal Data</h3>
                  <p>
                    Your Personal Data is retained only as long as necessary for
                    the purposes set out in the policy, to comply with our legal
                    obligations, resolve disputes, and enforce our legal
                    agreements and policies.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Transfer of Your Personal Data</h3>
                  <p>
                    Your information, including Personal Data, may be
                    transferred to — and maintained on — computers located
                    outside of your state or country where the data protection
                    laws may differ from those in your jurisdiction.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Disclosure of Your Personal Data</h3>
                  <p>
                    Disclosure may occur under certain circumstances such as
                    with Service Providers, for business transfers, with
                    affiliates, and with business partners as outlined in our
                    policy.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Security of Your Personal Data</h3>
                  <p>
                    The security of Your Personal Data is important to us but
                    remember that no method of transmission over the Internet or
                    method of electronic storage is 100% secure.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Children's Privacy</h3>
                  <p>
                    Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from children under 13.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Links to Other Websites</h3>
                  <p>
                    Our Service may contain links to other websites not operated
                    by us. We strongly advise you to review the Privacy Policy
                    of every site you visit.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Changes to this Privacy Policy</h3>
                  <p>
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                  </p>
                  <h3 style={{ fontSize: "var(--h4)" }}>Contact Us</h3>
                  <p>
                    If you have any questions about this Privacy Policy, you can
                    contact us by email at{" "}
                    <a href="mailto:hello@zuse.lk">hello@zuse.lk</a> or by phone
                    at{" "}
                    <a href="tel:+94761368132">+94761368132</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
