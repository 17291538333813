import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import { slugify } from "../utils";
import ServiceData from "../data/service/ServiceMain.json";
import ProjectData from "../data/project/ProjectData.json";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";
import FormThree from "./../component/contact/FormThree";
import BreadCrumbOne from './../elements/breadcrumb/BreadCrumbOne';

const allServiceData = ServiceData;

const ServiceDetails = () => {
	const params = useParams();
	const serviceSlug = params.slug;

	const getServiceData = allServiceData.filter(
		(data) => slugify(data.title) === serviceSlug
	);
	const detailsService = getServiceData[0];

	return (
		<>
			<SEO/>
			<ColorSwitcher />
			<main className="main-wrapper">
				<HeaderOne />
				<BreadCrumbOne title={detailsService.title} page={detailsService.title}/>
				<div className="section-padding">
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<div className="why-choose-us">
									<div className="section-heading heading-left">
										<span className="subtitle">{detailsService.slug}</span>
										<h3 className="title">{detailsService.subtitle}</h3>
										<p>{detailsService.description}</p>
									</div>
									<Accordion defaultActiveKey="1">
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<FaCompress /> {detailsService.pointTitleOne}
											</Accordion.Header>
											<Accordion.Body>
												{detailsService.pointDescOne}
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<FaCode /> {detailsService.pointTitleTwo}
											</Accordion.Header>
											<Accordion.Body>
												{detailsService.pointDescTwo}
											</Accordion.Body>
										</Accordion.Item>
										{/* <Accordion.Item eventKey="3">
											<Accordion.Header>
												<FaGlobe /> {detailsService.pointTitleThree}
											</Accordion.Header>
											<Accordion.Body>
												{detailsService.pointDescThree}
											</Accordion.Body>
										</Accordion.Item> */}
									</Accordion>
								</div>
							</div>
							<div className="col-xl-5 col-lg-6 offset-xl-1">
								<div className="contact-form-box shadow-box mb--30">
									<h3 className="title">Start a project ?</h3>
									<FormThree />
								</div>
							</div>
						</div>
					</div>
				</div>
				<FooterOne parentClass="" />
			</main>
		</>
	);
};

export default ServiceDetails;
