import React from 'react';
import { Helmet } from 'react-helmet';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SplashCta from '../component/cta/SplashCta';
import TeamTwo from '../component/team/TeamTwo';
import BreadCrumbOne from './../elements/breadcrumb/BreadCrumbOne';


const Team = () => {

    return (
        <>
        <SEO/>
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
				<BreadCrumbOne title="Our Team" page="Team"/>
                {/* <TeamOne /> */}
                <TeamTwo />
                <SplashCta />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Team;