import React from 'react';

const Data = [
    {
        image: "/images/zuse_brands/lyceum-international-school.webp"
    },
    {
        image: "/images/zuse_brands/dream-team.webp"
    },
    {
        image: "/images/zuse_brands/book-studio.webp"
    },
    {
        image: "/images/zuse_brands/lyceum-campus.webp"
    },
    {
        image: "/images/zuse_brands/bitrock.webp"
    },
    {
        image: "/images/zuse_brands/lyceum-assessment.webp"
    },
    {
        image: "/images/zuse_brands/nextgen-publications.webp"
    },
    {
        image: "/images/zuse_brands/ncg-warehouse-solutions.webp"
    },
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="brand"/>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;