import React from 'react';
import { Link, useParams } from 'react-router-dom';
import WidgetCategory from './widget/WidgetCategory';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaPinterest, FaYoutube} from "react-icons/fa";
import BlogData from "../../data/blog/BlogData.json";

const allBlogData = BlogData;

const BlogSidebar = () => {

    const params = useParams();
    const blogSlug = params.slug;

	const getBlogData = allBlogData.filter(
		(blog) => blog.route_url === params.slug
	);
	const detailsBlog = getBlogData[0];

    return (
        <div className="axil-sidebar">
            <div className="widget widget-search">
                <h4 className="widget-title">Other Products <br/>By Zuse Technologies</h4>
            </div>
            <div className="widget widget-categories">
                <h4 className="widget-title">Categories</h4>
                <WidgetCategory />
            </div>
            <div className="widget widge-social-share">
                <div className="blog-share">
                    <h5 className="title">Follow:</h5>
                    <ul className="social-list list-unstyled">
                        <li><a href="https://facebook.com/"><FaFacebookF /></a></li>
                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                        <li><a href="https://www.instagram.com/"><FaInstagram /></a></li>
                        <li><a href="https://www.linkedin.com/"><FaLinkedinIn /></a></li>
                        <li><a href="https://www.pinterest.com/"><FaPinterest /></a></li>
                        <li><a href="https://www.youtube.com/"><FaYoutube /></a></li>
                    </ul>
                </div>
            </div>
            <div className="widget widget-banner-ad">
                <Link to="#">
                    <img src={`${process.env.PUBLIC_URL}/${detailsBlog.phone_view}`} alt="banner"/>
                </Link>
            </div>
        </div>
    )
}

export default BlogSidebar;