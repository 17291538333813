import React from 'react'
import SectionTitle from '../../elements/section-title/SectionTitle'

const Datas = [
	{
		id: 1,
		title: 'Exceed clients’ and colleagues’ expectations',
		para: 'Go above and beyond the expectations set by clients and colleagues alike. Elevate the standard of satisfaction for both our clients and team members.',
	},
	{
		id: 2,
		title:
			'Take ownership and question the status quo in a constructive manner',
		para: 'Demonstrate accountability by taking ownership, while also positively challenging the existing norms. Encourage innovation by questioning the status quo in a constructive and forward-thinking manner.',
	},
	{
		id: 3,
		title:
			'Be brave, curious and experiment – learn from all successes and failures',
		para: 'Embrace courage and curiosity to embark on bold experiments, using both successes and failures as valuable learning experiences. Approach challenges fearlessly, stay curious, and engage in experimentation, drawing insights from both achievements and setbacks.',
	},
	{
		id: 4,
		title: 'Act in a way that makes all of us proud',
		para: 'Conduct yourself in a manner that instills pride in all of us. Strive to act in a way that reflects positively on the collective.',
	},
	{
		id: 5,
		title: 'Build an inclusive, transparent and socially responsible culture',
		para: 'Foster an environment of inclusivity, transparency, and social responsibility. Work towards creating a culture that embraces diversity, open communication, and ethical engagement with society.',
	},
]

const AboutThree = () => {
	return (
		<div className='section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20'>
			<div className='container'>
				<SectionTitle
					subtitle='Our Values'
					title='Why should you work with us?'
					description='Working with us provides a range of compelling incentives. Our collaboration promises valuable benefits and opportunities.'
					textAlignment='heading-left heading-light-left mb--100'
					textColor=''
				/>

				<div className='row'>
					{Datas.map((data) => (
						<div className='col-lg-4' key={data.id}>
							<div className='about-quality'>
								<div className='sl-number' style={{ fontSize: "var(--h3)" }}>{data.id}</div>
								<h3 className='title' style={{ fontSize: "var(--h5)" }}>{data.title}</h3>
								<p>{data.para}</p>
							</div>
						</div>
					))}
				</div>
			</div>
			<ul className='list-unstyled shape-group-10'>
				<li className='shape shape-1'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/circle-1.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-2'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/line-3.png'}
						alt="shape"
					/>
				</li>
				<li className='shape shape-3'>
					<img
						src={process.env.PUBLIC_URL + '/images/others/bubble-5.png'}
						alt="shape"
					/>
				</li>
			</ul>
		</div>
	)
}

export default AboutThree
