import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaTiktok,
  FaGithub,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import BlogData from "../../data/blog/BlogData.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;
const getBlogData = BlogData;

const FooterOne = ({ parentClass }) => {
  const openLinkInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <div className="footer-social-link">
            <ul className="list-unstyled">
              <li>
                <FaFacebookF
                  onClick={() =>
                    openLinkInNewTab(
                      "https://web.facebook.com/zusetechnologies"
                    )
                  }
                />
              </li>
              <li>
                <FaTwitter
                  onClick={() =>
                    openLinkInNewTab("https://twitter.com/@zusetechnologie")
                  }
                />
              </li>
              <li>
                <FaTiktok
                  onClick={() =>
                    openLinkInNewTab("https://www.tiktok.com/@zusetech")
                  }
                />
              </li>
              <li>
                <FaLinkedin
                  onClick={() =>
                    openLinkInNewTab(
                      "https://www.linkedin.com/company/zusetechnologies/"
                    )
                  }
                />
              </li>
              <li>
                <FaInstagram
                  onClick={() =>
                    openLinkInNewTab("https://www.instagram.com/zusetech__/")
                  }
                />
              </li>
              <li>
                <FaGithub
                  onClick={() =>
                    openLinkInNewTab("https://github.com/Zuse-Technologies/")
                  }
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h3 className="title" style={{ fontSize: "var(--h2)" }}>
                    Get in touch!
                  </h3>
                  <p>
                    Our team is available to discuss your project requirements
                    and provide you with a personalized solution that exceeds
                    your expectations. Get in touch with us today!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-4">
                  <div className="footer-widget">
                    <h4
                      className="widget-title"
                      style={{ fontSize: "var(--h6)" }}
                    >
                      Solutions
                    </h4>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getServiceData.slice(0, 3).map((data, index) => (
                          <li key={index}>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/solutions/${slugify(data.title)}`
                              }
                            >
                              {data.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="footer-widget">
                    <h4
                      className="widget-title"
                      style={{ fontSize: "var(--h6)" }}
                    >
                      Products
                    </h4>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        {getBlogData.slice(0, 2).map((data, index) => (
                          <li key={index}>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/products/${slugify(data.route_url)}`
                              }
                            >
                              {data.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h4
                      className="widget-title"
                      style={{ fontSize: "var(--h6)" }}
                    >
                      Support
                    </h4>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/blog"}>
                            Blog
                          </Link>
                        </li>
                        <li>
                          <a href="https://careers.zuse.lk/" target="_blank">
                            Careers
                          </a>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            Privacy Policy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 p-0">
                  <div className="footer-widget">
                    <a
                      href="https://topweb.lk/june2024/zuse/"
                      target="_blank"
                      rel="nofollow external"
                    >
                      <img
                        src="https://topweb.lk/wp-content/uploads/2024/06/LogoJune2024-150x150.jpg"
                        alt="TopWeb Badge"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a
                    href="https://zuse.lk/"
                    aria-label="all right reserved by zuse technologies"
                  >
                    Zuse Technologies (PVT)
                  </a>{" "}
                  Ltd
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    We Make{" "}
                    <b>
                      <i>IT</i>
                    </b>{" "}
                    Happen
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
